<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="确认发货"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <span style="margin-left:50px;">确认发货需要发货人、汽车牌照。</span>
        <a-form-item label="送货人">
          <a-select
            v-decorator="['deliver_user_id', {
              initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,
              rules: [
                { required: true, message: '请选择送货人' },
              ]
            }]"
            placeholder="请选择送货人"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
            :disabled="true"
            v-decorator="['phone_number', {
              initialValue: defaultUser.phone_number,
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="交通方式">
          <a-radio-group v-decorator="['transport_type', { initialValue: 'car' }]" @change="handleToInfo">
            <a-radio value="car">汽车</a-radio>
            <a-radio value="walk">步行</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="transportType == 'car'" label="汽车牌照">
          <a-select
            v-decorator="['car_id']"
            placeholder="请选择车牌号"
            @focus="fetchCarOptions"
            @load="loadingCarOptions"
            allow-clear
          >
            <a-select-option
              v-for="car in carOptions"
              :key="car.id"
              :value="car.id"
            >
              {{ car.car_no }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { message: '请输入备注' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findCarOptions } from '@/api/car'
import { subscribeOrderDeliver } from '@/api/subscribe_order'
import { additionalItemOrderDeliver } from '@/api/additional_item_order'
import { findTenantTaskUserOptions, findUser } from '@/api/user'

export default {
  name: 'SubscribeOrderDeliver',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    orderType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'subscribeOrderDeliver' }),
      submitting: false,
      defaultFileList: [],
      userOptions: [],
      defaultUser: {},
      carOptions: [],
      loadingCarOptions: false,
      loadingUserOptions: false,
      transportType: 'car',
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchUserOptions()
  },
  methods: {
    handleToInfo(e) {
      this.transportType = e.target.value
    },
    handlePhoneNumberChange(id) {
      this.form.setFieldsValue({ phone_number: undefined })
      if (!id) {
        return
      }
      findUser(id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ phone_number: res.data.phone_number })
        }
      })
    },
    fetchUserOptions() {
      this.loadingUserOptions = true
      findTenantTaskUserOptions({ task_type: 'express', order_id: this.serviceOrderId, order_type: this.orderType }).then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data.option_list
          this.defaultUser = res.data.default_user
        }
        this.loadingUserOptions = false
      })
    },
    fetchCarOptions() {
      this.loadingCarOptions = true
      findCarOptions({ order_type: this.orderType, order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.carOptions = res.data.option_list
        }
        this.loadingCarOptions = false
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          if (this.orderType === 'subscribe') {
            subscribeOrderDeliver(this.serviceOrderId, values).then((res) => {
              if (res.code === 0) {
                // 关闭模态框
                this.isShow = false
                // 告知父组件已完成
                this.$emit('completed')
              }
              this.submitting = false
            })
          } else {
            additionalItemOrderDeliver(this.serviceOrderId, values).then((res) => {
              if (res.code === 0) {
                // 关闭模态框
                this.isShow = false
                // 告知父组件已完成
                this.$emit('completed')
              }
              this.submitting = false
            })
          }
        }
      })
    }
  }
}
</script>
